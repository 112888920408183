export const clientNameFooter = "yogandoBr";
export const titleMain = "Aqui você terá todo o suporte para transformar sua paixão pelo Yoga em um negócio próspero.";
export const subtitleMain = "Vamos juntas?";

export const aspectRatioLogoAbout = '3'; // width/height
export const aspectRatioLogoMain = '1'; // width/height

export const borderRadiusButtons = '10px';

export const nomeFornecedorCompleto = "YOGANDOBR";
export const nomeFornecedor = "YOGANDOBR";
export const artigo = "a";
export const artigoCaps = "A";

export const footerSocialData = [
  {
    type: "material-community",
    name: "instagram",
    link: "https://www.instagram.com/yogandobr/",
    profile: "@yogandobr"
  },
  {
    type: "material-community",
    name: "youtube",
    link: "https://www.youtube.com/c/YogandoBR",
  },
  {
    type: "material-community",
    name: "whatsapp",
    link: "https://api.whatsapp.com/send?phone=5531999232817&text=Atendimento%20www.yogandobr.com%20-%20Envie%20uma%20mensagem%20que%20em%20breve%20retornaremos.",
    number: "(31) 99923-2817"
  },
  {
    type: "material-community",
    name: "facebook",
    link: "https://www.facebook.com/yogandobr/",
  },
];

export const drawerClosedLinks = [
  {
    title: "Fale Comigo",
    link: "https://api.whatsapp.com/send?phone=5531999232817&text=Atendimento%20www.yogandobr.com%20-%20Envie%20uma%20mensagem%20que%20em%20breve%20retornaremos.",
  },
];

export const linkAppleStore = "https://apps.apple.com/us/app/yogandobr/id1640267915"
export const linkPlayStore = "https://play.google.com/store/apps/details?id=app.web.mobile_yogando_br.twa"

export const hasOutsideAboutPage = true;
export const drawerOpenedLinks = [{
  title: "Assinar planos",
  link: "https://eyogandobr.com/appyogando/app-yogando-br/",
}];
